@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-bg: #F3F4F6;
  --ex-primary-bg : #ffffff;
  --secondary-bg: #f8f9fa;
  --text-color: #333333;
  --highlight-color: #d32f2f;
  --accent-color: #ffd700;
  --border-color: #e0e0e0;
  --shadow-color: rgba(0, 0, 0, 0.1);
}

/* html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
} */

body,
#root,
.app {
  /* padding: env(safe-area-inset); */
  font-family: "Poppins", sans-serif;
  background-color: var(--primary-bg);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

header {
  /* background-color: transparent; */
  /* background-color: red; */
  padding: 12px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /* box-shadow: 0 2px 10px var(--shadow-color); */
}

.header-container {
  /* border: 1px solid black; */
  min-height: 6vh;
  position: relative;
  width: 100%;
  z-index: -1;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-button {
  position: absolute;
  left:5%;
  background: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  padding: 5px;
  margin-right: 10px;
  z-index: 1003;

}

.back-button:hover {
  color: #5f5d5d;
}

.header-spacer {
  flex: 1;
}



.logo {
  max-width: 100%;
  height: auto;
  max-height: 60px;
}


@keyframes fadeInRise {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeInRise {
  animation: fadeInRise 0.8s ease-out forwards;
}


.logo-container {
  flex: 1;
  display: flex;
  justify-content: center;
  
}

.menu-container {
  position: absolute;
  right: 5%;
  display: flex;
  justify-content: flex-end;
  
}


.menu-icon {
  z-index: 1003;
  color: var(--text-color);
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
  padding: 10px;
}

.menu-icon:hover,
.menu-icon:focus {
  color: var(--text-color);
}

.dropdown-menu {
  position: fixed;
  top: 0;
  right: 0;
  background-color: var(--ex-primary-bg);
  border-left: 1px solid var(--border-color);
  box-shadow: -2px 0 10px var(--shadow-color);
  z-index: 1005;
  transform: translateX(100%);
  transition: transform 0.2s ease-out;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 20vw;
}

.dropdown-menu-enter {
  transform: translateX(0);
}

.dropdown-menu-exit {
  transform: translateX(100%);
}

.user-info,
.subscription-status {
  padding: 20px;
  border-bottom: 1px solid var(--border-color);
}


.menu-items {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.menu-item:last-child {
  margin-top: auto;
}

.menu-item:hover {
  background-color: var(--secondary-bg);
}

.menu-item i {
  margin-right: 15px;
  color: var(--text-color);
}

/* Ensure the icon is keyboard-focable */
.menu-icon:focus-visible {
  outline: 2px solid var(--highlight-color);
  outline-offset: 2px;
}


.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}

main {
  display: flex;
  justify-content: center; 
  align-items: center; 
  padding: 100px 0 80px;
  
}

.login-container {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  background: var(--ex-primary-bg);
  border-radius: 15px;
  padding: 40px;
  width: 100%;
  max-width: 55vh;
  box-shadow: 0 10px 30px var(--shadow-color);
  
}

.form-group {
  
  
  margin-bottom: 25px;
}

.form-group label {
  display: block;
  
  margin-bottom: 8px;
  color: var(--text-color);
  font-size: 0.9rem;
  font-weight: 600;
}

.form-group input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid var(--border-color);
  background-color: var(--secondary-bg);
  color: var(--text-color);
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-group input:focus {
  outline: none;
  border-color: var(--highlight-color);
  box-shadow: 0 0 0 3px rgba(211, 47, 47, 0.1);
}


.login-button {
  width: 100%;
  padding: 15px;
  background: var(--highlight-color);
  border: none;
  border-radius: 8px;
  color: var(--ex-primary-bg);
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.login-button button:hover,
.login-button button:focus {
  background-color: #b71c1c;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(211, 47, 47, 0.2);
}


.status-message {
  text-align: center;
  margin-top: 20px;
  font-style: italic;
  color: var(--highlight-color);
  font-size: 0.9rem;
}

.offline-message {
  background-color: var(--highlight-color);
  color: var(--primary-bg);
  text-align: center;
  padding: 10px;
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  display: none;
  z-index: 1001;
  font-weight: 600;
}


/* 
#mainContent {
  text-align: center;
  padding: 20px;
  width: 100%;
} */


/* 
.content-section {
  padding: 20px;
  text-align: left;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
} */

/* .content-section h2 {
  color: var(--text-color);
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: 600;
} */

/* .content-section p {
  margin-bottom: 15px;
  line-height: 1.6;
} */
/* 
.benefit-item,
.event-item,
.profile-item {
  background: var(--secondary-bg);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 10px var(--shadow-color);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.benefit-item:hover,
.event-item:hover,
.profile-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px var(--shadow-color);
} */

/* .benefit-item i,
.event-item i,
.profile-item i {
  display: flex;
  width: 1.5rem;
  justify-content: center;
  align-items: center;
  color: var(--highlight-color);
  margin-right: 15px;
  font-size: 1.5rem;
} */
/* 
.item-content {
  flex: 1;
}

.item-title {
  font-weight: 600;
  margin-bottom: 5px;
}

.item-description {
  font-size: 0.9rem;
  color: #666;
}

.bottom-nav {
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  left: 0;
  right: 0;
  background: var(--primary-bg);
  display: flex;
  justify-content: space-around;
  padding: 12px 0;
  box-shadow: 0 -2px 10px var(--shadow-color);
  z-index: 1000;
}

.nav-item {
  color: var(--text-color);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 600;
  transition: all 0.3s ease;
}

.nav-item i {
  font-size: 1.6rem;
  margin-bottom: 5px;
}

.nav-item.active {
  color: var(--highlight-color);
} */

/* ! using popup install */

.pwa-install-prompt {
  width: 30rem;
  /* height: 20rem; */
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #f0f0f0;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1010;
  animation: slideUp 0.3s ease-out;
}

.pwa-install-prompt-content {
  /* border: 1px solid black; */
  max-width: 30rem;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.pwa-install-prompt .close-icon {
  position: absolute;
  top: 2%;
  right: 2%;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
  color: #666;
}

.pwa-install-prompt-content h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 20px;
  color: #333;
}

.pwa-install-prompt-content p {
  margin-bottom: 15px;
  font-size: 16px;
  color: #666;
}


.ios-instructions {
  text-align: left;
  margin-bottom: 20px;
  padding-left: 20px;
}

.ios-instructions li span {
  
  margin-bottom: 10px;
  font-size: 14px;
  color: #555;
}

.popup-share-icon {
  width: 3rem;
  /* aspect-ratio: 1/1; */
  height: auto;
  vertical-align: middle;
  margin-right: 5px;
}

.popup-install-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s;
}

.popup-install-button:hover {
  background-color: #0056b3;
}

.popup-close-button {
  background-color: #6c6d6e;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s;
}

.popup-close-button:hover {
  background-color: #343434;
}

/* Calendar */



@keyframes slideUp {
  from {
    transform: translate(-50%, 100%);
  }

  to {
    transform: translate(-50%, 0);
  }
}

@media (max-width: 480px) {
  .pwa-install-prompt {
    padding: 15px;
    width: 95%;
  }
  .pwa-install-prompt-content{
    width: 90%;
  }

  .pwa-install-prompt-content h2 {
    font-size: 18px;
  }

  .pwa-install-prompt-content p,
  .pwa-install-prompt-content .ios-instructions li {
    font-size: 14px;
  }

  
  /* .install-button {
    font-size: 14px;
    padding: 10px 15px;
  } */
}


@media (max-width: 768px) {
  .logo {
    width: 50vw;
    height: auto;
  }

  .bottom-nav {
    padding: 2% 0 7%;

  }

  .back-button {
    font-size: 1.5rem;
    padding: 8px;
  }

  .menu-icon {
    font-size: 1.5rem;
    padding: 8px;
  }

  .dropdown-menu {
    width: 70%;
    max-width: 300px;
    height: 100vh;
  }


  .menu-item {
    padding: 15px;
    font-size: 1.1rem;
  }


}